<template>
<div>
<!--  <top_header />-->

  
  <div class="main-div">
    <router-view/>
  </div>
</div>
</template>

<script>
// import './assets/js/bootstrap'
// import './assets/js/jQuery'
// import './assets/js/main'
// import './assets/js/slick'

import ServerInterface from './interface/be_interface';
// import top_header from './components/navigate/top_header.vue';


export default {
  name: 'App',
  components: {
    // top_header,
  },
  beforeCreate() {
    let d = location.hostname.split(".");
    if(d && d.length) {
      if(d[0] == "www") d = d[1] + (d[2] ? "." + d[2] : "");
      else d = d[0] + (d[1] ? "." + d[1] : "");
    }
    this.$store.commit("setTaroDomain", d);
    this.$store.commit("setS3UrlPath", "https://s3.us-east-1.amazonaws.com/shop." + (d == 'localhost' ? 'testspace.click' : d));

    let s = new ServerInterface();

    if(this.$store.getters.token()) {
      s.getUserInfo({token: this.$store.getters.token()}).then(r => {
        this.$store.commit('setLoggedInUser', r);
      })
    }

    s.getBusinessCategories().then((r) => {
      if(r) {
        this.$store.commit('setBusinessCategories', r);
      }
    })
		
		s.listCountries().then(r => {
			this.$store.commit('setCountriesList', r);
		})
  }
}
</script>

<style>
@import "./assets/css/main.css";
@import "./assets/css/Bootstrap.css";
/*html, body {*/
/*  margin: 0px;*/
/*  padding: 0px;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  overflow: hidden;*/
/*}*/

/*#app {*/
/*  font-family: Avenir, Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  color: #2c3e50;*/
/*  width: 100%;*/
/*}*/

/*.main-div {*/
/*  overflow: auto;*/
/*  height: calc(100vh - 120px);*/
/*  padding: 10px;*/
/*}*/

/*h1 {*/
/*  text-align: center;*/
/*  margin-top: 0px;*/
/*  margin-bottom: 10px;*/
/*}*/

/*.button-navbar {*/
/*  display: inline-block;*/
/*	position: relative;*/
/*	cursor: pointer;*/
/*	outline: none;*/
/*	white-space: nowrap;*/
/*  padding: 12px;*/
/*  margin-left: 10px;*/
/*	!* line-height: 24px; *!*/
/*	background-color: #0ea083;*/
/*	color: #FFF;*/
/*	font-weight: 200;*/
/*  font-size: 1rem;*/
/*	text-transform: uppercase;*/
/*	!* letter-spacing: 1px; *!*/
/*	border: none;*/
/*	text-shadow: 1px 1px 1px rgba(0,0,0,0.2);*/
/*  !* margin-right: 30px; *!*/
/*}*/

/*.button-navbar:hover {*/
/*  background-color: #034403;*/
/*}*/

/*.button-large:hover{*/
/*	background-color: #00473e;*/
/*  border:solid 2px rgb(179, 154, 12)*/
/*}*/

/*.row-center-container {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/

/*.col-center-container {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  flex-direction: column;*/
/*}*/

/*.card {*/
/*    width: 100%;*/
/*    min-width: 300px;*/
/*    max-width: 600px;*/
/*}*/

/*.card h2, .card button {*/
/*  text-align: center;*/
/*}*/

/*.card-header {*/
/*    justify-content: center;*/
/*    text-align: center;*/
/*}*/

/*.link-container {*/
/*    margin: 10px 0px;*/
/*    text-align: left;*/
/*}*/

/*.link {*/
/*    color: cornflowerblue;*/
/*}*/

/*.link:hover {*/
/*    text-decoration: underline;*/
/*    cursor: pointer;*/
/*    color: blue;*/
/*}*/
</style>
