import { createStore } from 'vuex'
// import createPersistedState from "vuex-persistedstate";
// import ServerInterface from '@/interface/be_interface.js';

export default createStore({
  state:  {
      id: 1,
      logo:'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40px" height="40px"><path fill-rule="evenodd" clip-rule="evenodd" d="M55.1004 9.39498C49.014 13.1798 43.0416 19.1521 31.0969 31.0969L31.0945 31.0993C19.1512 43.0425 13.1794 49.0144 9.39498 55.1004C-3.13174 75.2449 -3.13158 100.755 9.39498 120.9C13.179 126.985 19.15 132.956 31.0901 144.897L31.0955 144.902L31.0969 144.903C43.0416 156.848 49.014 162.82 55.1004 166.605C75.2448 179.132 100.755 179.132 120.9 166.605C126.986 162.82 132.958 156.848 144.903 144.903L144.903 144.903C156.848 132.958 162.82 126.986 166.605 120.9C179.132 100.755 179.132 75.2449 166.605 55.1004C162.82 49.014 156.848 43.0417 144.903 31.0969C132.958 19.1521 126.986 13.1798 120.9 9.39498C100.755 -3.13158 75.2448 -3.13174 55.1004 9.39498ZM113 61C119.904 61 125.5 55.4035 125.5 48.5C125.5 41.5965 119.904 36 113 36C106.096 36 100.5 41.5965 100.5 48.5C100.5 55.4035 106.096 61 113 61ZM75.5 76C75.5 70.4772 71.0228 66 65.5 66H50.5V103.5C50.5 124.211 67.2893 141 88 141C90.5685 141 93.0767 140.742 95.5 140.25V116H88C81.0964 116 75.5 110.404 75.5 103.5V76ZM100.5 138.866C115.065 133.718 125.5 119.828 125.5 103.5V66H110.5C104.977 66 100.5 70.4772 100.5 76V138.866Z" fill="#02C652"/></svg>',
      token: '',
      loggedInUser: null,
      registeredShops:0,
      afterLogin: '/shop',
      business_categories: [],
      countriesList: [],
      country: '',
      plan: {id: '', title: '', duration: ''},
      s3UrlPath: "",
      taroDomain: "testspace.click"
  },
  // plugins: [createPersistedState()],
  getters: {
    token: () => (key="token") => {
      return sessionStorage.getItem(key) ? sessionStorage.getItem(key) : false;
    },
    loggedInUser: () => (key="loggedInUserInfo") => {
      return sessionStorage.getItem(key) ? JSON.parse(sessionStorage.getItem(key)) : false;
    },
    getShopLogo: (state) => (shopCode) => {
      return state.s3UrlPath+"/"+shopCode+"/shop_landing_page/shop_logo.png";
    },
    primaryShopLogo: (state) => (shopCode) => {
      return state.s3UrlPath+"/"+shopCode+"/shop_landing_page/shop_primary_logo.png";
    },
    profileShopLogo: (state) => (shopCode) => {
      return state.s3UrlPath+"/"+shopCode+"/shop_landing_page/shop_profile.png";
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      sessionStorage.setItem("token", token);
    },
    setAfterLogin(state, val) {state.afterLogin = val},
    setRegisteredShops(state, val){state.registeredShops = val},
    setLoggedInUser(state, info) {
      state.loggedInUser = info;
      sessionStorage.setItem("loggedInUserInfo", info ? JSON.stringify(info) : null);
    },
    setBusinessCategories(state, list) {state.business_categories = list;},
    setCountriesList(state, list) {state.countriesList = list;},
    setCountry(state, cty) {state.country = cty;},
    setPlan(state, p) {state.plan = p;},
    setS3UrlPath(state, v) {state.s3UrlPath = v},
    setTaroDomain(state, v) {state.taroDomain = v}
  },
  actions: {
  },
  modules: {
  },
})
