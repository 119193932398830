import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueMobileDetection from 'vue-mobile-detection'

//Fontawsome

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);

import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);
// import { far } from '@fortawesome/free-regular-svg-icons';
// library.add(far);
// import { dom } from "@fortawesome/fontawesome-svg-core";
// dom.watch();



const app = createApp(App);
app.use(router);
app.use(store);
app.component("fa", FontAwesomeIcon);
app.use(VueMobileDetection);


// app.use(VueGoogleMaps, {
//     load: {
//         key: 'AIzaSyC9H3WZMB17q8ZigpwAJXVyBP0yKatjMk8',
//     },
// });


app.mount('#app');
